import React, { lazy, useState, useEffect } from 'react';
import { Modal, Table, Spin } from 'antd';

const MasterlistForm = lazy(() => import('./MasterlistForm'));

const MasterlistModal = props => {
	const { visible, closeModal, data, getJoDetails } = props;
	const [joData, setJoData] = useState({
		pr: [],
		po: [],
		deliveredItems: [],
		releasedRm: [],
	});
	const [spinning, setSpinning] = useState(false);

	useEffect(() => {
		if (visible) {
			setSpinning(true);
			getJoDetails(data.id)
				.then(res => {
					setJoData(res);
				})
				.finally(() => setSpinning(false));
		}
	}, [visible]);

	const { pr, po, releasedRm, deliveredItems } = joData;
	return (
		<Modal
			style={{ top: 10 }}
			visible={visible}
			title={`${data.jo_num || ''}`}
			footer={null}
			onCancel={closeModal}
			width={1200}
		>
			{pr.length > 0 ? (
				<>
					<h4 style={{ fontWeight: 600 }}>PURCHASE REQUEST LIST</h4>
					<Table
						columns={[
							{
								key: 'prNumber',
								title: 'Purchase request no.',
								align: 'center',
								dataIndex: 'prNumber',
								width: '40%',
							},
							{
								key: 'date',
								title: 'Date',
								dataIndex: 'date',
								align: 'center',
								width: '30%',
							},
							{
								key: 'items',
								title: 'Item Count',
								align: 'center',
								width: '30%',
								render: val => val.items.length,
							},
						]}
						rowKey="id"
						style={{ margin: '10px 0' }}
						dataSource={pr}
						bodyStyle={{ overflowX: 'auto' }}
						pagination={false}
						size="small"
						bordered
					/>
				</>
			) : (
				<h4 style={{ fontWeight: 600, color: 'indianred' }}>NO PURCHASE REQUEST</h4>
			)}
			{po.length > 0 ? (
				<>
					<h4 style={{ fontWeight: 600 }}>PURCHASE ORDER LIST</h4>
					<Table
						columns={[
							{
								key: 'poNumber',
								title: 'Purchase order no.',
								align: 'center',
								dataIndex: 'poNumber',
								width: '40%',
							},
							{
								key: 'date',
								title: 'Date',
								dataIndex: 'date',
								align: 'center',
								width: '30%',
							},
							{
								key: 'items',
								title: 'Item Count',
								align: 'center',
								width: '30%',
								render: val => val.items.length,
							},
						]}
						rowKey="id"
						style={{ margin: '10px 0' }}
						dataSource={po}
						bodyStyle={{ overflowX: 'auto' }}
						pagination={false}
						size="small"
						bordered
					/>
				</>
			) : (
				<h4 style={{ fontWeight: 600, color: 'indianred' }}>NO PURCHASE ORDER</h4>
			)}
			{deliveredItems.length > 0 ? (
				<>
					<h4 style={{ fontWeight: 600 }}>DELIVERED RAW MATERIAL/S</h4>
					<Table
						columns={[
							{
								key: 'code',
								title: 'Code',
								align: 'center',
								dataIndex: 'code',
								width: '18%',
							},
							{
								key: 'materialSpecs',
								title: 'Material Specification',
								dataIndex: 'materialSpecs',
								align: 'center',
								width: '28%',
							},
							{
								key: 'invoice',
								title: 'Invoice No.',
								dataIndex: 'invoice',
								align: 'center',
								width: '18%',
							},
							{
								key: 'dr',
								title: 'Delivery Receipt',
								dataIndex: 'dr',
								align: 'center',
								width: '18%',
							},
							{
								key: 'quantity',
								title: 'Quantity',
								dataIndex: 'quantity',
								align: 'center',
								width: '18%',
							},
						]}
						rowKey="id"
						style={{ margin: '10px 0' }}
						dataSource={deliveredItems}
						bodyStyle={{ overflowX: 'auto' }}
						pagination={false}
						size="small"
						bordered
					/>
				</>
			) : (
				<h4 style={{ fontWeight: 600, color: 'indianred' }}>NO DELIVERED RAW MATERIAL/S</h4>
			)}

			{releasedRm.length > 0 ? (
				<>
					<h4 style={{ fontWeight: 600 }}>RELEASED RAW MATERIAL/S</h4>
					<Table
						columns={[
							{
								key: 'code',
								title: 'Code',
								align: 'center',
								dataIndex: 'code',
								width: '18%',
							},
							{
								key: 'materialSpecs',
								title: 'Material Specification',
								dataIndex: 'materialSpecs',
								align: 'center',
								width: '28%',
							},
							{
								key: 'quantity',
								title: 'Quantity',
								dataIndex: 'quantity',
								align: 'center',
								width: '18%',
							},
							{
								key: 'date',
								title: 'Date',
								dataIndex: 'date',
								align: 'center',
								width: '18%',
							},
							{
								key: 'remarks',
								title: 'Remarks',
								dataIndex: 'remarks',
								align: 'center',
								width: '18%',
							},
						]}
						rowKey="id"
						style={{ margin: '10px 0' }}
						dataSource={releasedRm}
						bodyStyle={{ overflowX: 'auto' }}
						pagination={false}
						size="small"
						bordered
					/>
				</>
			) : (
				<h4 style={{ fontWeight: 600, color: 'indianred' }}>NO RELEASED RAW MATERIAL/S</h4>
			)}
		</Modal>
	);
};

export default MasterlistModal;

import React, { useEffect, useState, useContext } from 'react';
import { Input, Icon, Dropdown, Menu } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import { MContext } from '../context/MasterlistContext';
import { Utils } from '../context/UtilsContext';
import { decodedToken, logout, isLoggedIn } from '../config/token';
import { headers } from '../config/token';
import { displayErrors } from '../config/display';
import Loader from '../components/Loader/Loader';
import JobOrderFilter from '../components/Filter/Filter';

import MainboardTable from '../components/MainboardTable';
import MainboardModal from '../components/Materials/MasterlistModal';

const API = process.env.REACT_APP_API_URL;

const SideMenu = styled.nav`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
`;

const Username = styled.span`
	margin: 0 10px;
	font-size: 16px;
	text-transform: uppercase;
`;

const defaultFilter = {
	search: '',
	sort: 'jo-desc',
	showRecord: 'Open',
	customer: null,
	year: null,
	month: null,
};

const HomeView = () => {
	const { setLoading, loading } = useContext(Utils);
	const [filter, setFilter] = useState(defaultFilter);
	const [modalData, setModalData] = useState({ visible: false, data: {} });
	const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 });
	const [data, setData] = useState({ customers: [], joList: [], joListLength: 0 });
	const [isLogOutClicked, setLogOut] = useState(false);

	const menu = (
		<Menu>
			<Menu.Item key="0">
				<span onClick={() => setLogOut(true)}>Log out</span>
			</Menu.Item>
		</Menu>
	);

	const { username } = decodedToken();
	// FUNCTIONS
	const openModal = data => setModalData({ visible: true, data });
	const closeModal = () => setModalData({ visible: false, data: {} });
	const getFilterParams = filter => {
		let params = '';
		Object.keys(filter).forEach(key => {
			params += filter[key] ? `&${key}=${filter[key]}` : '';
		});
		return params;
	};

	const getJoDetails = async id => {
		try {
			const res = await axios.get(`${API}pjoms/jo/${id}`, headers());
			return res.data;
		} catch (error) {
			displayErrors(error);
			throw new Error(error);
		}
	};

	const getJobOrders = async (useDefaultFilter, page = 1, shouldLoadAll = ``) => {
		const filterParams = getFilterParams(useDefaultFilter || filter);
		setLoading(true);
		try {
			const res = await axios.get(
				`${API}pjoms/jo?page=${page}${filterParams}${shouldLoadAll}`,
				headers()
			);

			if (page > 1) {
				const { joList } = res.data;
				setData(current => ({
					...current,
					joList: current.joList.concat(joList),
				}));
			} else setData(res.data);
		} catch (error) {
			displayErrors(error);
		} finally {
			setLoading(false);
		}
	};

	const resetPagination = () => setPaginate({ page: 1, pageSize: 10 });
	const onResetFilter = () => {
		resetPagination();
		getJobOrders(true);
		setFilter(defaultFilter);
	};

	const onFilter = () => {
		getJobOrders();
		resetPagination();
	};

	const shouldFetchFromServer = ({ page, pageSize }) => {
		const totalData = page * pageSize;
		const currentDataLength = joList.length; //
		const dataTotalLength = joListLength;
		let shouldReload = false;
		if (
			totalData > currentDataLength &&
			(dataTotalLength > totalData ||
				(dataTotalLength > totalData - pageSize && dataTotalLength > currentDataLength))
		) {
			shouldReload = true;
		}

		const shouldLoadAll =
			(totalData > dataTotalLength && dataTotalLength > totalData - pageSize) ||
			totalData === dataTotalLength
				? `&start=${currentDataLength}&end=${dataTotalLength}`
				: ``;

		return {
			shouldReload,
			newPage: Math.floor(currentDataLength / 1000) + 1,
			shouldLoadAll,
		};
	};

	const onPageChange = (page, pageSize) => {
		const { shouldReload, newPage, shouldLoadAll } = shouldFetchFromServer({
			page,
			pageSize,
		});
		if (shouldReload) {
			getJobOrders(false, newPage, shouldLoadAll);
		}
		setPaginate({ page, pageSize });
	};

	const onSizeChange = (page, pageSize) => {
		const { shouldReload, newPage, shouldLoadAll } = shouldFetchFromServer({
			page,
			pageSize,
		});
		if (shouldReload) {
			getJobOrders(false, newPage, shouldLoadAll);
		}
		setPaginate({ page, pageSize });
	};

	useEffect(() => {
		getJobOrders();
	}, []);

	if (isLogOutClicked || !isLoggedIn()) {
		logout();
		return <Redirect to="/" />;
	}

	const { customers, joList, joListLength } = data;
	return (
		<div style={style.wrapper}>
			{loading && <Loader />}
			<SideMenu>
				<Username>{username}</Username>
				<Dropdown overlay={menu} trigger={['click']}>
					<Icon type="down" />
				</Dropdown>
			</SideMenu>
			<JobOrderFilter
				filter={filter}
				onResetFilter={onResetFilter}
				onFilter={onFilter}
				setFilter={setFilter}
				customers={customers}
			/>
			<MainboardTable
				joList={joList}
				joListLength={joListLength}
				onPageChange={onPageChange}
				onSizeChange={onSizeChange}
				paginate={paginate}
				openModal={openModal}
			/>
			<MainboardModal
				getJoDetails={getJoDetails}
				visible={modalData.visible}
				data={modalData.data}
				closeModal={closeModal}
			/>
		</div>
	);
};

const style = {
	wrapper: {
		padding: '20px 30px',
		width: '100%',
		backgroundColor: '#f8feff',
		borderRadius: 4,
	},
};

export default HomeView;

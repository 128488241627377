import React from 'react';
import { Table, Icon, Button, Tooltip } from 'antd';

const MainboardTable = ({
	joList,
	joListLength,
	paginate,
	onPageChange,
	onSizeChange,
	openModal,
}) => {
	const columns = [
		{
			key: 'status',
			align: 'center',
			title: 'View',
			render: data => {
				return (
					<Tooltip placement="right" title="View J.O Details">
						<Button onClick={() => openModal(data)} icon="profile" className="bl-cl" />
					</Tooltip>
				);
			},
		},
		{
			key: 'jo_num',
			dataIndex: 'jo_num',
			title: 'Job Order',
			align: 'center',
		},

		{
			key: 'code',
			dataIndex: 'code',
			title: 'Code',
			align: 'center',
		},
		{
			key: 'itemDesc',
			dataIndex: 'itemDesc',
			title: 'Item Description',
			align: 'center',
		},
		{
			key: 'servedQty',
			title: 'Qty',
			align: 'center',
			dataIndex: 'quantity',
		},
		{
			key: 'customer',
			dataIndex: 'customer',
			title: 'Customer',
			align: 'center',
		},

		{
			key: 'poNum',
			dataIndex: 'poNum',
			title: "Customer's P.O",
			align: 'center',
		},
		{
			key: 'date_needed',
			dataIndex: 'date_needed',
			title: 'Date needed',
			align: 'center',
		},
		{
			key: 'pr',
			title: 'PR',
			align: 'center',
			render: data => {
				let status = 'NO PR';
				if (data.hasPr) status = 'WITH PR';

				return (
					<p className="gr-cl" style={{ fontWeight: 700 }}>
						{status}
					</p>
				);
			},
		},
		{
			key: 'po',
			title: 'PO',
			align: 'center',
			render: data => {
				let status = 'NO PO';
				if (data.hasPo) status = 'WITH PO';

				return (
					<p className="gr-cl" style={{ fontWeight: 700 }}>
						{status}
					</p>
				);
			},
		},
	];

	return (
		<Table
			bodyStyle={{ overflowX: 'auto' }}
			columns={columns}
			rowKey="id"
			dataSource={joList}
			size="middle"
			pagination={{
				total: joListLength,
				current: paginate.page,
				pageSize: paginate.pageSize,
				defaultPageSize: paginate.pageSize,
				showSizeChanger: true,
				onChange: onPageChange,
				onShowSizeChange: onSizeChange,
				pageSizeOptions: ['10', '25', '50', '100', '500'],
				showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
				position: 'both',
			}}
			bordered
		/>
	);
};

export default MainboardTable;
